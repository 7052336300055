<template>
  <div class="prize-card" :style="{backgroundColor: prizeFilter}">
    <p class="prize-card__label">Du hast gewonnen!</p>
    <p class="prize-card__value">€{{ value }}</p>
    <p class="prize-card__number">{{ hash }}</p>
    <div class="prize-card__logo">
      <LogoIcon />
    </div>
  </div>
</template>

<script>
import LogoIcon from '@/assets/images/logo.svg?inline'
export default {
  name: 'PrizeCard',
  components: {
    LogoIcon
  },
  props: {
    value: {
      type: String
    },
    hash: {
      type: String
    }
  },
  computed: {
    prizeFilter: function () {
      return (this.prizes
        .map(p => ({ qty: p.qty, color: p.color }))
        .filter(p => p.qty == this.value)[0] || this.badColor
      ).color
    }
  },
  data () {
    return {
      badColor: {
        color: '#f00'
      },
      prizes: [
        {
          qty: 5,
          color: '#CED9E3'
        },
        {
          qty: 10,
          color: '#EED3D3'
        },
        {
          qty: 20,
          color: '#E4EEEA'
        },
        {
          qty: 50,
          color: '#EAE6D4'
        },
        {
          qty: 1000,
          color: '#DAECDA'
        },
        {
          qty: 666,
          color: '#a341b8'
        },
        {
          qty: '00',
          color: 'transparent'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.prize-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 35px 20px 25px;
  width: 280px;
  color: $black;
  border-radius: 4px;
  margin: auto;
}

.prize-card__label {
  margin: 0 0 30px;
  font-size: 14px;
  line-height: 1.4;
}

.prize-card__value {
  margin: 0 0 10px;
  font-family: $accent-font;
  font-weight: 500;
  font-size: 74px;
  line-height: 1.1384;
}

.prize-card__number {
  margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.4;
}

.prize-card__logo {
  width: 94px;
  height: 18px;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}
</style>
