<template>
  <div class="prize" v-if="step==='prize'">
<!--    <div class="prize__temp">
      <button type="button" v-on:click="changeValue">5</button>
      <button type="button" v-on:click="changeValue">10</button>
      <button type="button" v-on:click="changeValue">20</button>
      <button type="button" v-on:click="changeValue">50</button>
      <button type="button" v-on:click="changeValue">1000</button>
    </div>-->
    <div class="prize__tip">
      <Tip :backFn="skipArView" text="Hole dir jetzt deine Geschenkkarte im H&M HOME Concept Store ab" />
    </div>
<!--    <router-link to="/validation" class="prize__card">-->
      <PrizeCard :value='prizeValue' :hash="hash" :ontouchstart="ontouchstart" :ontouchend="ontouchend"/>
<!--    </router-link>-->
    <div class="prize__footer">
      <p>Hier klicken für die <a href="https://hm-home-store-opening.com/cgu_hm-home.pdf" target="_blank">Allgemeinen Geschäftsbedingungen</a></p>
    </div>
  </div>
  <Validation v-if="step==='validation'" :goto="goto"/>
  <Validation2 v-if="step==='validation2'" :goto="goto"/>
  <Validated v-if="step==='validated'" :goto="goto"/>
</template>

<script>
import Tip from '@/components/Tip.vue';
import PrizeCard from '@/components/PrizeCard.vue';
import Validation from '@/views/Validation.vue';
import Validation2 from '@/views/Validation2.vue';
import Validated from '@/views/Validated.vue';

export default {
  name: 'Prize',
  components: {
    Tip,
    PrizeCard,
    Validation,
    Validation2,
    Validated,
  },
  data () {
    return {
      prizeValue: '00',
      hash: '#xxxxxxxxxxxx',
      step: 'prize'
    };
  },
  methods: {
    goto: function(step) {
      this.step = step;
      store.set('prizeStep', step);
    },
    skipArView: function() {
      router.go(-1);
      setTimeout(function() {
        if(StateController.isSameHash(location.hash,'/#/ar-view')){
          router.go( -1 );
        }
      },30);
    },
    ontouchstart(e){
      e.preventDefault();
      e.stopPropagation();
      var _self = this;
      LinesAnimator.animateLines(async function() {
        var validationResult;
        if(ARController.secret.secret === 'santa'){
          validationResult = true;
        }else{
          validationResult = await Ajax.async.post( '/api/lottery/justValidate', {
            secret: ARController.secret.secret,
            id: ARController.PrizeController.get().id
          } );
        }
        if(validationResult){
          _self.step = 'validation';
          //router.push( { path: 'validation' } );
        }else{
          alert('Prize has already been taken');
          router.push( { path: 'ar-experience' } );
        }

      });
    },
    ontouchend(e){
      LinesAnimator.unAnimateLines();
    },
    changeValue (e) {
      this.prizeValue = e.target.innerText
    }
  },
  mounted: async function() {
    store.set('currentPage', 'Prize');
    //store.set('ar', true);
    store.sub(['prizeNotify'], () => {
      var winning = ARController.PrizeController.get();
      if(winning.win){
        this.prizeValue = winning.prize;
        this.hash = winning.id;
      }
    });
    store.sub(['prizeStep'], (step) => {
      this.step = step;
    });
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.prize {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: $background-color;
}

.prize__temp {
  position: absolute;
  top: 100px;
  right: 20px;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;

  button {
    margin: 5px;
    padding: 10px;
  }
}

.prize__card {
  margin: auto;
  text-decoration: none; //temp
}

.prize__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px;
  text-align: center;
  font-size: 14px;

  p {
    margin: 0 auto 20px;
    max-width: 260px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    font-weight: 700;
    border-bottom: 1px solid $white;
  }
}
</style>
